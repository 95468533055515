import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Axios from "axios";
import Helmet from "helmet";

function Category() {
  const [blogData, setBlogData] = useState([]);
  const { id } = useParams();


  useEffect(() => {
    //GET EVERYTHING IN THE BLOG POSTS  
    Axios.get(`https://blogserver.friendlyapk.com/category/${id}`).then((res) => {
      setBlogData(res.data);
    });
    
  }, []);
  return (
    <main className='categoryPage'>
      
      <h2>{id}</h2>
      <section>
        {blogData.map((value) => {
          return(
            <Link to={`../profile/${value.blogid}`} key={`${value.blogid}`}>
              <Helmet>
                <title>{value.category}</title>
                <meta name="description" content={`${value.category} consist list of all blogs and can be sorted by category. `} />
                <meta name="keywords" content={`${value.category}, category, blogs list, blogs`} />
              </Helmet>
              <div className="blogcard">
                <img src={value.img} alt={value.title} />
                <p className="categoryColor">{value.category}</p>
                <h3>{value.title}</h3>
                <p>Read More...</p>
              </div>
            </Link>
          )
        })}
      </section>
    </main>
  )
}

export default Category