import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Axios from "axios";
import HtmlParser from 'html-react-parser';
import Helmet from "helmet";
function Profile() {

  const { id } = useParams();
  const [blogData, setBlogData] = useState([]);
  const [blogList, setBlogList] = useState([]);

   // Get DATA FROM DATABASE SELECT EVERYTHING
   useEffect(() => {
    // eslint-disable-next-line
    Axios.get(`https://blogserver.friendlyapk.com/api/fullpage/${id}`).then((res) => {
      setBlogData(res.data);
    });
     //GET EVERYTHING IN THE BLOG POSTS
     Axios.get("https://blogserver.friendlyapk.com/api/recommended").then((res) => {
      setBlogList(res.data);
    });
  }, []);
  return (
   <main>
    {blogData.map((value) => {
      return(
        <section className="blog" key={value.blogid}>
          <Helmet>
                <title>{value.title}</title>
                <meta name="description" content={`${value.title}, ${value.category}`} />
                <meta name="keywords" content={`${value.category}, ${value.tag} `} />
              </Helmet>
        <p className="blogCategory">{value.category}</p>
        <h2 className="blogTitle">{value.title}</h2>
        <img src={value.img} alt={value.tag}  />
        <div className="blogContent">
        <div>
          {(HtmlParser(value.code))}
        </div>
        </div>
        </section>
      )
    })}
    <section>
      <h3 className="recemmended">You May Like These Blogs</h3>
      <div className="blogrid">
      {blogList.map((value) => {
          return (
            <Link to={`../profile/${value.blogid}`} key={`${value.blogid}`}>
              <div className="blogcard">
                <img src={value.img} alt={value.title} />
                <p className="categoryColor">{value.category}</p>
                <h3>{value.title}</h3>
                <p>Read More...</p>
              </div>
            </Link>
          );
        })}
      </div>
    </section>
   </main>
  )
}

export default Profile