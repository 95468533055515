import React from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import ScrollToTop from "./ScrollToTop";
import Helmet from "helmet";

//PAGES IMPORT CODE
import Latest from "./Latest";
import Category from "./Category";
import Profile from "./Profile";
import Featured from "./Featured";
import Header from "./Header";
import Footer from "./Footer";
function App() {
  return (
    <div className="App">
      <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <title>Friendlyapk</title>
      <meta name="description" content="friendlyapk.com is a controller or gamepad, Vr and Tablet friendly library of smartphone games. " />
      <meta name="author" content="One Men Studio .com" />
      <meta name="HandheldFriendly" content="True" />
      <meta name="MobileOptimized" content="320" />
      <meta name="keywords" content="controller compatible games, android Vr game, tablet games, best controller, vr headset, best tablet" />
      <link rel="manifest" href="/manifest.json" />
      <title>Blog Friendlyapk</title>
      </Helmet>
      <ScrollToTop />
      <Header />
      <Routes basename="/">
      <Route path="/" element={<Featured />} />
      <Route path="/Latest" element={<Latest />} />
      <Route path="/category/:category" element={<Category />} />
      <Route path="/profile/:id" element={<Profile />} />
      <Route  />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
