import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";

function Featured() {
  const [blogList, setBlogList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    //GET EVERYTHING IN THE BLOG POSTS
    Axios.get("https://blogserver.friendlyapk.com/api/latestblog").then((res) => {
      setBlogList(res.data);
    });
    // GET EVERY CATEGORY
    Axios.get("https://blogserver.friendlyapk.com/api/getcategory").then((res) => {
      setCategoryList(res.data);
    });
  }, []);
  return (
    <main className="Featured">
      <section className="featureblog">
      <h1>Featured Blog</h1>
      <div className="blogrid">
        {blogList.map((value) => {
          return (
            <Link to={`../profile/${value.blogid}`} key={`${value.blogid}`}>
              <div className="blogcard">
                <img src={value.img} alt={`${value.title}`} effect="blur" />
                <p className="categoryColor">{value.category}</p>
                <h3>{value.title}</h3>
                <p>Read More...</p>
              </div>
            </Link>
          );
        })}
      </div>
      </section>

      <section className="category">
        <h2>Browse By Category</h2>
        <div className="categorygrid">
          {categoryList.map((value) => {
            return (
              <Link to={`../category/${value.name}`} key={`${value.categoryid}`}>
                <div className="categorycard">
                  <p>{value.name}</p>
                </div>
              </Link>
            );
          })}
        </div>
      </section>
    </main>
  );
}

export default Featured;
