import React from "react";
import { Link } from "react-router-dom";
import Logo from "./img/gamepad.png"; 
function Header() {
   
  return (
    <header>
        <div>
           <a href="https://friendlyapk.com" target="_blank" rel="noreferrer noopener" ><img src={Logo} alt="Logo of Friendlyapk" /> <h1> FriendlyApk Blog</h1> </a> 
        </div>
      <div>
        <Link to="../">Home</Link>
        <Link to="../latest">Latest</Link>
        <Link to="../latest"><img src="https://i.ibb.co/2tV74wN/search.png" alt="Search" /></Link>
      </div>
    </header>
  );
}

export default Header;
