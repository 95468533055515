import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DOMPurify from 'dompurify';
import Axios from "axios";
import Helmet from "helmet";

function Latest() {
  const [blogList, setBlogList] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);

  useEffect(() => {
    //GET EVERYTHING IN THE BLOG POSTS
    Axios.get("https://blogserver.friendlyapk.com/api/getblog").then((res) => {
      setBlogList(res.data);
    });
  }, []);

  const searchQuery = (e) => {
   const term = DOMPurify.sanitize(searchTerm);
   console.log(term);
    if(term !== "" || term !== " "){
    Axios.get(`https://blogserver.friendlyapk.com/api/search/${term}`).then((res) => {
      setBlogList(res.data);
    })
  } 
    e.preventDefault();
  };
  const searchReset = (e) => {
    Axios.get("https://blogserver.friendlyapk.com/api/getblog").then((res) => {
      setBlogList(res.data);
    });
    e.target.value = "";
  }

  return (
    <main>
      <Helmet>
                <title>Latest friendlyapk.com blogs</title>
                <meta name="description" content={`Latest list of all blogs with category.`} />
                <meta name="keywords" content={`Latest blog, friendlyapk, controller games, controller friendly game, Vr friendly game, `} />
              </Helmet>

      <h1>All Latest Blog</h1>
      <div className="searchbar">
        <input type="text" onChange={(e) => {
          setSearchTerm(e.target.value)
        }} />
        <img src="https://i.ibb.co/2tV74wN/search.png" alt="Search"  onClick={searchQuery} />
        <img src="https://i.ibb.co/yhRbSg4/refresh.png" alt="Reset" onClick={searchReset} />
      </div>

      <div className="blogrid">
        {blogList.map((value) => {
          return (
            <Link to={`../profile/${value.blogid}`} key={`${value.blogid}`}>
              <div className="blogcard">
                <img  src={value.img} alt={`${value.title} Attribute`} />
                <p className="categoryColor">{value.category}</p>
                <h3>{value.title}</h3>
                <p>Read More...</p>
              </div>
            </Link>
          );
        })}
      </div>
    </main>
  );
}

export default Latest;
